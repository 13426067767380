import React from 'react';
import styled from "styled-components";
import Header from "./utils/Header.js";
import { MainBody, Text, Intro } from "./Kantoor";
import { device } from "./utils/deviceBreakpoints";


const MainBodyLocal = styled(MainBody)`
    margin-bottom: 10vh;
    @media ${device.mobileL} {
        margin-bottom: 5vh;
    }
`;

const List = styled.ul`
    padding-inline-start: 0px;
`;

const ListItem = styled.li`
    list-style-type: none;
`;

const ListTitle = styled.h3`
    font-size: 2em;
`;

const Paragraph = styled.p`
`;

const Extro = styled(Intro)`
    margin-top: 4em;
    margin-bottom: 1em;
`;

export default function Body({id}){
    return(
        <MainBodyLocal id={id}>
            <Header title="Rechtsgebieden" />
            <Text>
                <List id="Rechtsgebieden">
                    <ListItem id="pfrecht">
                        <ListTitle>Personen- en familierecht</ListTitle>
                        <List id="pfrechtList">
                            <ListItem>Echtscheiding/beëindiging geregistreerd partnerschap</ListItem>
                            <ListItem>Boedelscheiding/afwikkeling huwelijkse voorwaarden</ListItem>
                            <ListItem>Alimentatie</ListItem>
                            <ListItem>Gezag</ListItem>
                            <ListItem>Omgang</ListItem>
                            <ListItem>Hoofdverblijf</ListItem>
                            <ListItem>Afstamming</ListItem>
                            <ListItem>Adoptie</ListItem>
                            <ListItem>Ondertoezichtstelling</ListItem>
                            <ListItem>Uithuisplaatsing</ListItem>
                            <ListItem>Voogdij</ListItem>
                            <ListItem>Pleegzorg</ListItem>
                            <ListItem>Internationale kinderontvoering</ListItem>
                            <ListItem>Overige Boek 1 BW-zaken</ListItem>
                        </List>
                    </ListItem>
                    <ListItem id="arbeidsrecht">
                        <ListTitle>Arbeidsrecht</ListTitle>
                        <List id="arrechtList">
                            <ListItem>Beëindiging arbeidsovereenkomst</ListItem>
                            <ListItem>Loonvorderingen</ListItem>
                        </List>
                    </ListItem>
                    <ListItem>
                        <ListTitle>Sociaal zekerheidsrecht</ListTitle>
                    </ListItem>
                    <ListItem>
                        <ListTitle>Sociale voorzieningen</ListTitle>
                    </ListItem>
                    <ListItem>
                        <ListTitle>Huurrecht</ListTitle>
                    </ListItem>
                    <ListItem>
                        <ListTitle>Vermogens- en verbintenissenrecht</ListTitle>
                    </ListItem>
                    <ListItem>
                        <ListTitle>Erfrecht</ListTitle>
                    </ListItem>
                </List>
                <Extro>Voorts kan mijn kantoor voor vragen op het gebied van ondernemings- en vennootschapsrecht een beroep doen op de kennis en ervaring van de juridisch adviseur.</Extro>
            </Text>
        </MainBodyLocal>
    )
}