import React from 'react';
import styled from "styled-components";
import Header from "./utils/Header.js";
import { MainBody, Text } from "./Kantoor";
import { device } from "./utils/deviceBreakpoints";


const MainBodyLocal = styled(MainBody)`
    margin-bottom: 10vh;
    @media ${device.mobileL} {
        margin-bottom: 30vh;
    }
`;

const List = styled.ol`
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: ordered-list;
`;

const ListItem = styled.li`
    margin-bottom: 12px;
    
    ::before {
        display: block; 
        margin-bottom: 4px;
        counter-increment: ordered-list;
        content: "---  " counter(ordered-list) "  ---";
        font-size: 1.3em;
    }
`;

export default function Body({id}){
    return(
        <MainBodyLocal id={id}>
            <Header title="Algemene Voorwaarden" />
            <Text>
                <List>
                    <ListItem>Dorhout advocatuur is de eenmanszaak van de bij de Nederlandse Orde van Advocaten ingeschreven advocaat mr. G.R. Dorhout-Tielken. Aan het kantoor is verbonden de juridisch adviseur mr. A.F. Dorhout.</ListItem>
                    <ListItem>Deze algemene voorwaarden zijn van toepassing op alle opdrachten die de cliënten aan de advocaat verstrekken. De toepasselijkheid van eventuele algemene voorwaarden van cliënten worden uitdrukkelijk uitgesloten.</ListItem>
                    <ListItem>Dorhout advocatuur heeft ten behoeve van de uitoefening van de werkzaamheden van de advocaat en de juridisch adviseur een beroepsaansprakelijkheidsverzekering afgesloten die voldoet aan de daaraan door de Nederlandse Orde van Advocaten gestelde eisen.</ListItem>
                    <ListItem>Iedere aansprakelijkheid van de advocaat of de juridisch adviseur is in haar totaliteit beperkt tot het bedrag dat in het desbetreffende geval op grond van de door Dorhout advocatuur afgesloten beroepsaansprakelijkheidsverzekering wordt uitbetaald, te vermeerderen met het eigen risico dat op grond van deze verzekering ten laste komt van de advocaat dan wel de juridisch adviseur. Indien en voor zover om welke reden dan ook geen uitkering krachtens genoemde verzekering plaatsvindt is iedere aansprakelijkheid van Dorhout advocatuur beperkt tot € 1.000,-- of, indien het honorarium dat in de betreffende zaak aan cliënt in rekening is gebracht meer beloopt, tot het beloop van het honorarium met een maximum van € 25.000,--.</ListItem>
                    <ListItem>Dorhout advocatuur beschikt niet over een Stichting Beheer Derdengelden en kan geen derdengelden ontvangen.</ListItem>
                    <ListItem>Bij het inschakelen van derden zal de advocaat de nodige zorgvuldigheid in acht nemen. Behalve in het geval van de inschakeling van deurwaarders zal de advocaat indien dit redelijkerwijs mogelijk is met de cliënt overleggen over deze inschakeling. Eventuele aansprakelijkheidsbeperkingen van derden worden door de advocaat aanvaard mede namens de cliënt.</ListItem>
                    <ListItem>Alle aanspraken van opdrachtgevers en derden vervallen indien deze niet schriftelijk en gemotiveerd zijn ingediend bij de advocaat binnen één jaar nadat de opdrachtgever respectievelijk de derde bekend was, of redelijkerwijs kon zijn, met de feiten waarop hij zijn aanspraak baseert.</ListItem>
                    <ListItem>De betalingstermijn van de door de advocaat aan cliënt gezonden declaraties is, behoudens andersluidende afspraak, 14 dagen te rekenen vanaf de datum van de declaratie. Bij gebreke van betaling binnen deze termijn is cliënt van rechtswege in verzuim en heeft de advocaat het recht de wettelijke rente in rekening te brengen.</ListItem>
                    <ListItem>Dorhout advocatuur zal het dossier van de opdracht gedurende 5 jaren na het einde van de opdracht bewaren. Na afloop van die termijn wordt het vernietigd.</ListItem>
                    <ListItem>Op de rechtsverhouding tussen opdrachtgever en de advocaat is alleen Nederlands recht van toepassing. Geschillen zullen uitsluitend worden beslecht door de bevoegde rechter te Utrecht (Rechtbank Midden-Nederland).</ListItem>
                    <ListItem>Op de dienstverlening van de advocaat is de Klachten- en Geschillenregeling Advocatuur van toepassing. De klacht dient binnen drie maanden na het moment waarop cliënt kennis neemt of redelijkerwijs had kunnen nemen van het handelen of nalaten dat aanleiding heeft gegeven tot de klacht, te worden voorgelegd.</ListItem>
                </List>
            </Text>
        </MainBodyLocal>
    )
}