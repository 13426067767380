import React from 'react';
import styled from "styled-components";

import Header from "./utils/Header.js";
import { MainBody } from "./Kantoor";

const ContactBody = styled.div``;

const Info = styled.div``;

const Map = styled.div``;

const InfoItems = styled.ul`
    padding-inline-start: 0px;
`;

const InfoItem = styled.li`
    list-style-type: none;
`;

export default function Body({id}){
    return(
        <MainBody id={id}>
            <Header title="Contact" />
            <ContactBody>
                <Info>
                    <InfoItems>
                        <InfoItem><a href="mailto:info@dorhout-advocaten.nl">info@dorhout-advocaten.nl</a></InfoItem>
                        <InfoItem>Valeriaanstraat 38</InfoItem>
                        <InfoItem>3765 EP Soest</InfoItem>
                        <InfoItem>T: 035-6090494</InfoItem>
                        <InfoItem>F: 035-6090496</InfoItem>
                    </InfoItems>
                </Info>
                <Map></Map>
            </ContactBody>
        </MainBody>
    )
}