import React, {Component} from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import styled from "styled-components";

import { device } from "./content/utils/deviceBreakpoints";
import menu from "./content/images/menu.svg";

const MainBody = styled.div`
    background: #33404d;
    position: fixed;
    width: inherit;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    border-radius: 0px 0px 10px 10px;

    @media ${device.mobileL} {
        padding-top: 10px;
    }
`;

const Head = styled.div`
    flex:2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const TitleComponent = styled.div`
    text-align: center;
`;

const Title = styled.h1`
    font-size: 3em;
    cursor: pointer;
    margin: 8px;
    color: white;
    font-family: 'Hepta Slab', serif;
    
    @media ${device.mobileL} {
        font-size: 1.2em;
    }
`;

const MenuComponent = styled.nav`
`;

const Menu = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333333;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-radius: 0px 0px 10px 10px;

    @media ${device.mobileL} {
        display: ${props => props.collapsed ? "flex" : "none"};
        flex-direction: column;
    }
`;

const MenuItem = styled.li`
    float: left;
`;

const MenuIcon = styled.img`
    display: none;
    @media ${device.mobileL} {
        display: block;
        margin: auto;
    };
`;

const StyledLink = styled(Link)`
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
    cursor: pointer;

    :hover& {
        color: lightgrey;
    }
`;

const ContactComponent = styled.div`
    @media ${device.mobileL} {
        display: none;
    }
`;

const ContactInfo = styled.ul`
    list-style-type: none;
    text-align: center;
    margin: 3px;
    padding: 0px;
    font-size: 0.8em;
    color: #383838;
`;

const ContactInfoItem = styled.li`
    color: white;
`;

export default class Body extends Component {
    state = {collapsed: false}

    menuCollapse = () => {
        this.setState((prev) => ({collapsed: !prev.collapsed}))
    }
    
    scrollToTop = () => {
        scroll.scrollToTop();
      };

      render(){
        let defaultOffset = -200;
        return(
            <MainBody>
                <Head>
                    <TitleComponent>
                        <Title onClick={this.scrollToTop}>Dorhout Advocatuur</Title>
                        <MenuIcon src={menu} onClick={this.menuCollapse}/>
                    </TitleComponent>
                    <ContactComponent>
                        <ContactInfo>
                            <ContactInfoItem><a href="mailto:info@dorhout-advocaten.nl" style={{"textDecoration": "none", "color": "white"}}>info@dorhout-advocaten.nl</a></ContactInfoItem>
                            <ContactInfoItem>035-6090494</ContactInfoItem>
                        </ContactInfo>
                    </ContactComponent>
                </Head>          
                <MenuComponent>
                    <Menu collapsed={this.state.collapsed}>
                        <MenuItem><StyledLink
                            onClick={this.menuCollapse}
                            activeClass="active" 
                            to="kantoor" 
                            spy={true}
                            smooth={true}
                            offset={defaultOffset}
                            duration= {500}>Kantoor</StyledLink>
                        </MenuItem>
                        <MenuItem><StyledLink
                            onClick={this.menuCollapse}
                            activeClass="active" 
                            to="rg" 
                            spy={true}
                            smooth={true}
                            offset={defaultOffset}
                            duration= {500}>Rechtsgebieden</StyledLink>
                        </MenuItem>
                        <MenuItem><StyledLink 
                            onClick={this.menuCollapse}
                            activeClass="active" 
                            to="kosten" 
                            spy={true}
                            smooth={true}
                            offset={defaultOffset}
                            duration= {500}>Kosten</StyledLink>
                        </MenuItem>
                        <MenuItem><StyledLink 
                            onClick={this.menuCollapse}
                            activeClass="active" 
                            to="av" 
                            spy={true}
                            smooth={true}
                            offset={defaultOffset+20}
                            duration= {500}>Algemene voorwaarden</StyledLink>
                        </MenuItem>
                        <MenuItem><StyledLink 
                            onClick={this.menuCollapse}
                            activeClass="active" 
                            to="contact" 
                            spy={true}
                            smooth={true}
                            offset={defaultOffset}
                            duration= {500}>Contact</StyledLink>
                        </MenuItem>
                    </Menu>
                </MenuComponent>
            </MainBody>
        )
      }
}