import React from 'react';
import styled from "styled-components";

const Body = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const Title = styled.h2`
    color: #33404d;
    font-size: 3em;
    text-align: right;
    margin: 0;
`;

export default function ContentHeader(props) {
    return(
        <Body>
            <Title>{props.title}</Title>
        </Body>
    )
}