import React from 'react';
import Header from "./utils/Header.js";
import { Text, Paragraph, MainBody } from "./Kantoor";

export default function Body({id}){
    return(
        <MainBody id={id}>
            <Header title="Kosten" />
            <Text>
                <Paragraph>Germaine Dorhout is ingeschreven bij de Raad voor Rechtsbijstand en verleent rechtsbijstand op zgn. “toevoegingsbasis” (gefinancierde rechtsbijstand). In ieder dossier wordt bij aanvang nagegaan of de betrokken cliënt in aanmerking komt voor gefinancierde rechtsbijstand. In die gevallen is de cliënt aan de advocaat een door de Raad voor Rechtsbijstand vast te stellen eigen bijdrage verschuldigd. De voorwaarden om in aanmerking te komen voor gefinancierde rechtsbijstand zijn te vinden op www.rvr.org.</Paragraph>
                <Paragraph>Naast de eigen bijdrage is de cliënt in voorkomende gevallen de griffierechten, eventuele kosten van uittreksels uit de burgerlijke stand en niet onder de toevoeging vallende deurwaarderskosten verschuldigd.</Paragraph>
                <Paragraph>Als de cliënt niet voor gefinancierde rechtsbijstand in aanmerking komt zullen bij aanvang van de zaak passende en redelijke afspraken over het in rekening te brengen uurtarief gemaakt worden. Bovenop het afgesproken uurtarief is de cliënt 5% kantoorkosten en 21% BTW verschuldigd en in voorkomende gevallen de door mijn kantoor te maken kosten, zoals griffierechten, uittreksels, kosten deurwaarder of andere in overleg met de cliënt in te schakelen derden.</Paragraph>
            </Text>
        </MainBody>
    )
}