import React from 'react';
import styled from "styled-components";

import Header from "./utils/Header.js";
import { device } from "./utils/deviceBreakpoints";

export const MainBody = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    list-style-type: none;
    font-size: 1em;
    color: #383838;

    @media ${device.mobileL} {
        margin-bottom: 30vh;
    }
    
`;

const MainBodyLocal = styled(MainBody)`
    padding-top:250px;
    @media ${device.mobileL} {
        padding-top: 150px;
    }
`;

export const Text = styled.div`
    background: #546a80;
    margin: 0px;
    padding: 50px;
    border-radius: 10px;
    color: white;   
`;

export const Subtitle = styled.h3`
    font-size: 2em;
`;
export const Paragraph = styled.p`
`;

export const Intro = styled(Paragraph)`
    font-size: 1.4em;
    margin-bottom: 4em;
`;

export default function Body({id}){
    return(
        <MainBodyLocal id={id}>
            <Header title="Kantoor" />
            <Text>
                <Intro id="intro">Dorhout advocatuur is de eenmanszaak van de advocaat mr. G.R. Dorhout-Tielken. Aan het kantoor is haar echtgenoot mr. A.F. Dorhout verbonden als juridisch adviseur.</Intro>
                <Subtitle id="lawyer">Mr. G.R. Dorhout-Tielken</Subtitle>
                <Paragraph id="description">Germaine Dorhout heeft rechten gestudeerd aan de Rijksuniversiteit Groningen en is sinds 1 januari 1989 advocaat in Soest. Het accent van haar algemene praktijk ligt op het personen- en familierecht, waaronder in het bijzonder het jeugdrecht. Zij heeft veel ervaring in echtscheidingsprocedures en een breed scala aan overige familie- en jeugdrechtzaken. Zij wordt met enige regelmaat door de Rechtbank Midden-Nederland benoemd tot bijzonder curator. De werkwijze van Germaine Dorhout kenmerkt zich door een directe aanpak en een persoonlijke benadering van cliënten. Waar mogelijk zal zij steeds streven naar het voorkomen van (verdere) escalaties en het in samenwerking met de cliënt bereiken van het meest optimale resultaat</Paragraph>
                <Paragraph id="other">Germaine Dorhout heeft in het rechtsgebiedenregister van de Nederlandse Orde van Advocaten (NOVA) de volgende rechtsgebieden geregistreerd:</Paragraph>
                <Paragraph id="other">Personen- en familierecht</Paragraph>
                <Paragraph id="other">Deze registratie verplicht Germaine Dorhout elk kalenderjaar volgens de normen van de NOVA tien opleidingspunten te behalen op ieder geregistreerd rechtsgebied.</Paragraph>
                <Paragraph />
                <Paragraph id="other">Naast haar advocatenpraktijk is Germaine Dorhout sinds 1 januari 2005 voorzitter van de klachtencommissie van een landelijk werkende jeugdzorginstelling (GI).</Paragraph>
                <Subtitle id="lawyer">Mr. A.F. Dorhout</Subtitle>
                <Paragraph id="description">Toon Dorhout heeft rechten gestudeerd aan de Universiteit Leiden. Na zijn studie heeft hij 11 jaar gewerkt bij een grote internationaal werkende onderneming in Utrecht. In 1981 is Toon Dorhout als advocaat beëdigd in Amsterdam. Vanaf 1989 tot 2002 was hij als advocaat werkzaam in Soest. Hij hield zich, naast de algemene praktijk, voornamelijk bezig met het adviseren van bedrijven op het gebied van ondernemings-, vennootschaps-, arbeids- en bouwrecht. Om fysieke redenen heeft Toon Dorhout zich in 2002 laten uitschrijven als advocaat. Daarna is hij als juridisch adviseur aan het kantoor verbonden gebleven. Als advocaat en vervolgens als juridisch adviseur hecht Toon Dorhout veel waarde aan een grondige analyse van de achtergronden en oorzaken van problemen en geschillen. Dit leidt tot beter begrip van de zaak en draagt op die wijze bij aan het bereiken van een oplossing.</Paragraph>
            </Text>
        </MainBodyLocal>
    )
}