import React from 'react';
import styled from "styled-components";

import { device } from "./content/utils/deviceBreakpoints";

import Header from "./Header";
import Kantoor from "./content/Kantoor";
import Rechtsgebieden from "./content/Rechtsgebieden";
import Kosten from "./content/Kosten";
import AlgVoorwaarden from "./content/AlgVoorwaarden";
import Contact from "./content/Contact"

const Main = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  margin: -8px auto;
  width: 70vw;
  font-family: 'Roboto', sans-serif;

  @media ${device.tablet} {
    width: 95vw;
  }  
  
`;

function App() {
  return (
    <Main className="App">
      <Header />
      <div>
        <Kantoor id="kantoor" />
        <Rechtsgebieden id="rg" />
        <Kosten id="kosten" />
        <AlgVoorwaarden id="av" />
        <Contact id="contact" />
      </div>
    </Main>
  );
}

export default App;
